@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  line-height: inherit;
  max-width: 100%;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

input::placeholder,
textarea::placeholder {
  color: hsla(50, 72%, 92%, 0.5) !important;
}
